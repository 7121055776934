// *****************************************************************
//JS: lovell-banners.js
// Lovell Rugby website functions for site banners
// (c) 2017 Lovell Rugby Limited

function cordovaDeviceReady() {
  var platform = device.platform.toLowerCase().replace(/[\W]+/g, '_');
  if (platform === 'ios') {
    $('body.ios .home-page-main-banner')
      .find('.vimeo-wrapper')
      .closest('.main-banner-item')
      .remove();
  }
}

$(document).ready(function () {
  // ********************
  // Home page banner
  // ********************

  // remove vimeo items from ios app - they don't work
  document.addEventListener('deviceready', cordovaDeviceReady, false);

  // run the carousel plugin on the main home banner
  // swapResponsiveVideo('video[data-srcxs][data-srcsm][data-srcmd][data-srclg]');
  // swapResponsiveIframe(
  //   'div.vimeo-wrapper[data-srcxs][data-srcsm][data-srcmd][data-srclg]'
  // );
  if (document.querySelector('.banner .swiper')) {
    $('.banner .main-banner-item:first').imagesLoaded(function () {
      var swiper = new Swiper('.banner .swiper', {
        loop: true,
        slidesPerView: 1,
        autoHeight: false,
        lazyPreloadPrevNext: 1,
        autoplay: {
          delay: 7000,
          pauseOnMouseEnter: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          slideChange: function () {
            runVideoInBanner(this);
          },
          init: function () {
            // runVideoInBanner(this);
          },
        },
      });
    });
  }

  function runVideoInBanner(banner) {
    var currentFrame = banner.slides[banner.activeIndex];
    var video = $(currentFrame).find('iframe'); 
    if (video.length) {
      banner.autoplay.stop();
    }
  }

  // ******************
  // Splash page banner
  // ******************
  // setup colors for splash banner using the rgb values provided in the data attributes
  if($('.splash-header').length) {
    $('.splash-header').css('background-color', '#' + $('.splash-page-banner').attr('data-bg-color'));
  }

  if($('.splash-header-text').length) {
    if($('.main-banner-elements').length) {
      if($('.main-banner-elements').attr('data-title-color').length) {
        $('.splash-header-text h1').css('color', '#' + $('.main-banner-elements').attr('data-title-color'));
      }
      if($('.main-banner-elements').attr('data-body-color').length) {
        $('.splash-header-text .group-text').css('color', '#' + $('.main-banner-elements').attr('data-body-color'));
      }
      if($('.main-banner-elements').attr('data-links_color').length) {
        $('.splash-header-text .group-text a').css('color', '#' + $('.main-banner-elements').attr('data-links_color'));
      }
    }
  }

  // if on mobile, truncate the SEO text
  if($('.splash-header .group-text').length && getDeviceType() == 'mobile' && $('.splash-page-banner').length) {
    // if there's an h1 title in the text - extract it before shortening the text
    if($('.splash-header .group-text h1').length) {
      $('.splash-header .group-text h1').prependTo('.splash-header-text');
    }
    // apply text shortening function on SEO text to keep mobile output small.
    $('.splash-header .group-text').lc_txt_shortener('<span class="ellipses">...</span>', 80, 'p, strong, a, h1, h2, h3');
  }

  // show or hide the 'more' button as appropriate
  if($('.group-text').hasClass('lcnb_shorten')) {
    $('.splash-header-readmore').removeClass('hidden');
  } else {
    $('.splash-header-readmore').addClass('hidden');
  }

  // handle the 'more' button link (show hide full SEO text)
  $('.splash-header-readmore').on('click', function() {
    if($('.group-text').hasClass('lcnb_shorten')) {
      $('.splash-header .group-text').lc_txt_unshorten();
      $('.splash-header-readmore').addClass('hidden');
      $('.splash-header').css('min-height', $('.splash-page-banner .main-banner-image').outerHeight() / 2 + $('.splash-page-banner .main-banner-elements').outerHeight());
    } else {
      $('.splash-header .group-text').lc_txt_shortener('<span class="ellipses">...</span>', 80, 'p, strong, a');
      $('.splash-header-readmore').removeClass('hidden');
    }
  });
  // move the splash banner text div into the main banner so that it stretches the outer banner div when the text is lengthened
  if($('.splash-header-text').length) {
    $('.splash-header-text').appendTo('.splash-page-banner .main-banner-elements');
    $('.splash-page-banner .main-banner-elements').removeClass('hidden-xs');
  }

  if($('.splash-page-banner').length) {
    $('.splash-page-banner').imagesLoaded().always(function() {
      $('.splash-header').css('height', 'auto').css('opacity', 1);
    });
  } else {
    $('.splash-header').css('height', 'auto').css('opacity', 1);
  }

  // move splash page banner out of the main container in order to allow it to be full width
  if($('.splash-header').length) {
    $('.splash-header').insertBefore($('.main-container'));
  }

  $('.chooser-banner').imagesLoaded().always(function() {
    $('.main-banner-elements').css('opacity', 1);
  });

});

$(function () {
  if ($('#offers-banner').length) {
    var viewportWidth = $(window).width();
    var $offersBanner = $('#offers-banner');
    var $mainBannerImage = $offersBanner.find('.main-banner-image');
    var totalWidth = 0;

    if ($mainBannerImage.length && $mainBannerImage.outerWidth() > 0) {
      while (totalWidth <= viewportWidth) {
        // Clone the main-banner-image div
        var $clone = $mainBannerImage.clone().appendTo($offersBanner);

        // Update the total width
        totalWidth += $mainBannerImage.outerWidth(true); // Include margins

        // Update the viewport width (in case of window resizing)
        viewportWidth = $(window).width();
      }
      $('#offers-banner .main-banner-image').css(
        'animation-play-state',
        'running'
      );
    }
  }
});
