// *****************************************************************
//JS: lovell-sliders.js
// Lovell Rugby website functions for slider panels
// (c) 2017 Lovell Rugby Limited

$(document).ready(function () {
  hideSliderPlaceholders();
  applySliderButtonCSS();

  // feature slider
  if ($('.feature-slider').length) {
    $('.feature-slider').each(function (index, slider) {
      $(slider)
        .find('.swiper-button-next')
        .addClass('swiper-button-next-' + index);
      $(slider)
        .find('.swiper-button-prev')
        .addClass('swiper-button-prev-' + index);
      var swiper = new Swiper($(slider).find('.swiper')[0], {
        loop: false,
        spaceBetween: 10,
        slidesOffsetAfter: 10,
        slidesOffsetBefore: 10,
        centerInsufficientSlides: true,
        navigation: {
          nextEl: '.swiper-button-next-' + index,
          prevEl: '.swiper-button-prev-' + index,
          enabled: false,
        },
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 2.4,
            slidesPerGroup: 2,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2.4,
            slidesPerGroup: 2,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3.4,
            slidesPerGroup: 3,
          },
          860: {
            slidesPerView: 4.4,
            slidesPerGroup: 4,
            slidesOffsetAfter: 10,
            slidesOffsetBefore: 10,
            spaceBetween: 10,
            navigation: {
              enabled: false,
            },
          },
          1080: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0,
            spaceBetween: 30,
            navigation: {
              enabled: true,
            },
          },
          1300: {
            slidesPerView: 5,
            slidesPerGroup: 5,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0,
            spaceBetween: 30,
            navigation: {
              enabled: true,
            },
          },
        },
      });
    });
  }

  // tabbed sliders *************************
  $(document).on('click', '.tabbed-sliders__header-item', function () {
    var activeItemIndex = $(this).index();
    // add the active class to both mobile and full tabs
    $('.tabbed-sliders__header-item').removeClass('is-active');
    $(this)
      .closest('.tabbed-sliders__header')
      .find('.tabbed-sliders__header-items')
      .each(function (e) {
        $('.tabbed-sliders__header-item', this)
          .eq(activeItemIndex)
          .addClass('is-active');
      });

    // add the active class to the slider panel
    $(this)
      .closest('.tabbed-sliders')
      .find('.tabbed-sliders__slider-item')
      .removeClass('is-active');
    $(this)
      .closest('.tabbed-sliders')
      .find('.tabbed-sliders__slider-item')
      .eq(activeItemIndex)
      .addClass('is-active');
  });

  // show the number of products in each slider next to the full (not mobile) slider tabs
  $('.tabbed-sliders__header-items--full .tabbed-sliders__header-item').each(
    function (index, element) {
      var itemCount = $('.tabbed-sliders__slider-item')
        .eq(index)
        .find('.slider__item').length;
      $(this).find('span').remove();
      $(this).append(
        ' <span class="tabbed-sliders__header-item-count">(' +
          itemCount +
          ')</span>'
      );
    }
  );
}); // end doc ready

function resizeSliders(slider) {
  if (slider && slider.is(':visible')) {
    var tallest = 0;
    var titleHeight = 0;
    var tallestImage = 0;
    var imageHeight = 0;
    var sliderItemHasNowPrice = false;
    $('.owl-item', slider).each(function (o, j) {
      if ($('.slider__item-cost.now', j).length) {
        sliderItemHasNowPrice = true;
      }
      titleHeight = $('.slider__item-title', j).outerHeight();
      if (titleHeight > tallest) {
        tallest = titleHeight;
      }
      imageHeight = $('.slider__item-image img', j).outerHeight();
      if (imageHeight > tallestImage) {
        tallestImage = imageHeight;
      }
    });
    // set the height of all the titles in the slider to the height of the tallest one
    $('.slider__item-title', slider).css('min-height', tallest);
    $('.slider__item-image', slider).css('min-height', tallestImage);
    if (sliderItemHasNowPrice) {
      $('.slider__item-cost', slider)
        .not('.now')
        .not('.rrp')
        .css('margin-bottom', '34px');
    }

    // alter padding if slider nav controls aren't going to be invoked
    if (slider.hasClass('slider--suggesteditemsslider')) {
      if (
        ($(document).outerWidth() >= 960 &&
          $(slider).find('.owl-item').length <= 4) ||
        ($(document).outerWidth() >= 1400 &&
          $(slider).find('.owl-item').length <= 5)
      ) {
        $(slider).addClass('no-padding');
      } else {
        $(slider).removeClass('no-padding');
      }
    }
  }
}

function applySliderButtonCSS() {
  $('[data-slider-button-bg]').each(function (e) {
    $(this)
      .find('.slider__item-button--view-product')
      .css('background-color', $(this).attr('data-slider-button-bg'));
  });
}

function hideSliderPlaceholders() {
  // hides the FEATURESLIDER placeholder for any sliders that did not find any products
  $('.slider-panel').each(function () {
    if ($(this).text().indexOf('FEATURESLIDER') >= 0) {
      $(this).addClass('hidden');
    } else {
      $(this).removeClass('hidden');
    }
  });
}
